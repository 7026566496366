import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseOutlined, EmojiEmotionsOutlined } from "@mui/icons-material";
import { LangConstant } from "const";
import { CommonUtils } from "utils";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EmojiPicker from "emoji-picker-react";

const CreateMessageDialog = ({ open, onClose, onCreate, onUpdate, onDraft }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const textAreaRef = useRef(null);

  const [content, setContent] = useState("");
  const [time, setTime] = useState(null);
  const [isOpenEmoji, setOpenEmoji] = useState(false);

  const handleChangeForm = event => {
    setContent(event.target.value);
  };

  const handleSubmit = () => {
    onCreate(content, time);
  };

  const handleDraft = () => {
    onDraft(content);
  };

  const onEmojiClick = emojiObject => {
    const cursorPosition = textAreaRef.current.selectionStart; 
    const textBeforeCursor = content.substring(0, cursorPosition); 
    const textAfterCursor = content.substring(cursorPosition); 
    setContent(textBeforeCursor + emojiObject.emoji + textAfterCursor);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Box sx={{ display: "flex", justifyContent: "space-between", height: "40px", backgroundColor: "#F2F6FC" }}>
        <DialogTitle sx={{ fontWeight: "bold", width: "88%", padding: "6px", paddingLeft: "40%" }}>
          Soạn tin nhắn
        </DialogTitle>
        <Button onClick={onClose}>
          <CloseOutlined fill="black" />
        </Button>
      </Box>

      <DialogContent sx={{ paddingTop: "6px" }}>
        <Box component="form" className={classes.form}>
          <TextareaAutosize
            className={classes.textArea}
            minRows="20"
            maxRows="25"
            onChange={handleChangeForm}
            onClick={() => setOpenEmoji(false)}
            value={content}
            ref={textAreaRef}
          />

          <Box className={classes.buttonContainer}>
            <Box>
              <EmojiEmotionsOutlined sx={{ cursor: "pointer" }} onClick={() => setOpenEmoji(!isOpenEmoji)} />
              {isOpenEmoji && (
                <Box sx={{ position: "fixed", bottom: "10%", zIndex: "1" }}>
                  <EmojiPicker emojiStyle="apple" emojiVersion="3.0" onEmojiClick={onEmojiClick} />
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="contained" color="primary" onClick={handleDraft} disabled={!Boolean(content)}>
                Lưu bản nháp
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!Boolean(content) || time <= Date.now()}
              >
                Gửi
              </Button>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  sx={{ width: "220px" }}
                  label="Lên lịch"
                  minDate={Date.now()}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={e => setTime(e.getTime())}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateMessageDialog;

const useStyles = makeStyles(() => ({
  form: {
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },

  textArea: {
    boxSizing: "border-box",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "8px 12px",
    borderRadius: "8px",
    boxShadow: "0 0 5px 2px",

    "&:hover": {
      boxShadow: "0 0 5px 3px cornflowerblue",
    },
  },
}));
