import apisauce from "apisauce";
import QueryString from "qs";
import i18next from "language";
import { ApiConstant, KeyConstant, LangConstant } from "const";
import { AuthUtils, HumpUtils, StorageUtils } from "utils";

export const API_CONFIG = {
  baseURL: ApiConstant.BASE_URL,
  headers: ApiConstant.HEADER_DEFAULT,
  timeout: ApiConstant.TIMEOUT,
  paramsSerializer: params => {
    return QueryString.stringify(params, { arrayFormat: "repeat" });
  },
};

export const createApiByConfig = (initConfig = API_CONFIG) => {
  const branch_domain = ApiConstant.BRANCH_DOMAIN;
  if (Boolean(branch_domain) && branch_domain !== "") {
    const url = "https://" + branch_domain + "/dev/v1";
    initConfig = {
      ...initConfig,
      baseURL: url,
    };
  }
  return apisauce.create(initConfig);
};

// TODO: remove force when update offline server
export const createApiWithToken = (force, initConfig = API_CONFIG, token = null) => {
  try {
    let appToken = token || StorageUtils.getStoreData(KeyConstant.KEY_ACCESS_TOKEN);

    if (appToken) {
      initConfig.headers.Authorization = `Bearer ${appToken}`;
    }

    if (!force) {
      const currentDomain = StorageUtils.getStoreData(KeyConstant.KEY_CURRENT_DOMAIN);
      if (Boolean(currentDomain) && currentDomain !== "") {
        const url = "https://" + currentDomain + "/dev/v1";
        initConfig = {
          ...initConfig,
          baseURL: url,
        };
      }
    }

    const api = apisauce.create(initConfig);

    // Check 401 status
    api.addAsyncResponseTransform(async response => {
      if (response.status === ApiConstant.STT_UNAUTHORIZED) {
        alert(i18next.t(LangConstant.TXT_LOGIN_EXPIRED));
        AuthUtils.handleLogout();
      }
    });

    return api;
  } catch (error) {
    alert(i18next.t(LangConstant.TXT_UPDATE_ERROR));
    AuthUtils.handleLogout();
  }
};

export const apiGetAvatar = async (item, isAvatar) => {
  const avatarId = isAvatar ? item.avatarId : item.attachmentId;
  const response = await createApiWithToken(false, {
    ...API_CONFIG,
    responseType: "blob",
  }).get(ApiConstant.GET_FILE_ATTACHMENT, HumpUtils.decamelizeKeys({ attachmentId: avatarId }));
  if (response.status === ApiConstant.STT_OK) {
    return response.data;
  } else {
    return "";
  }
};

export const apiCreateUserActivitiesLog = async (actionType, branchId = null) => {
  try {
    const initConfig = { ...API_CONFIG };
    const branchList = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST) || [];
    const created = Date.now();
    const userName = StorageUtils.getStoreData(KeyConstant.KEY_USERNAME);
    const payload = HumpUtils.decamelizeKeys({ actionType, created, userName });

    if (branchId) {
      const branch = branchList.find(currentBranch => currentBranch.id === branchId);
      const baseUrl = "https://" + branch.domain + "/dev/v1";
      const appToken = branch.access_token;
      if (appToken) initConfig.headers.Authorization = `Bearer ${appToken}`;
      initConfig.baseURL = baseUrl;
      const api = apisauce.create(initConfig);
      return api.post(ApiConstant.CREATE_USER_ACTIVITIES_LOG, QueryString.stringify(payload));
    } else {
      const promises = branchList.map(branch => {
        const appToken = branch.access_token;
        if (appToken) initConfig.headers.Authorization = `Bearer ${appToken}`;
        const baseUrl = "https://" + branch.domain + "/dev/v1";
        initConfig.baseURL = baseUrl;
        const api = apisauce.create(initConfig);
        return api.post(ApiConstant.CREATE_USER_ACTIVITIES_LOG, QueryString.stringify(payload));
      });
      return Promise.all(promises);
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
