import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Typography,
} from "@mui/material";
import { LangConstant, SystemConstant } from "const";
import theme from "theme/material";
import BranchRequestStatus from "./BranchRequestStatus";

const BranchRequestListTable = ({
  isLoading,
  branchRequestList,
  pagination,
  checkedUsers,
  onCheckBoxChange,
  refetch,
  onChangePage,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const handleCheckboxChange = (isCheckAll, isChecked, id) => {
    onCheckBoxChange(isCheckAll, isChecked, id);
  };

  return (
    <Box>
      <TableContainer sx={{ height: "520px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" width="5%">
                <Checkbox
                  onChange={e => handleCheckboxChange(true, e.target.checked, null)}
                  checked={
                    checkedUsers.length > 0 && checkedUsers.length ===
                    branchRequestList.filter(
                      data => data.status === SystemConstant.BRANCH_REQUEST_STATUS.REJECTED.status,
                    ).length
                  }
                  size="small"
                />
              </TableCell>
              <TableCell align="left" width="10%">
                <strong>Họ và tên</strong>
              </TableCell>
              <TableCell align="left" width="13%">
                <strong>Số điện thoại</strong>
              </TableCell>
              <TableCell align="left" width="13%">
                <strong>Email</strong>
              </TableCell>
              <TableCell align="left" width="28%">
                <strong>Ghi chú từ người yêu cầu</strong>
              </TableCell>
              <TableCell align="center" width="13%">
                <strong>Trạng thái</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <small>{getLabel(LangConstant.TXT_LOADING)}</small>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {branchRequestList.length > 0 ? (
                branchRequestList.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <Checkbox
                        size="small"
                        onChange={e => handleCheckboxChange(false, e.target.checked, user.id)}
                        checked={checkedUsers.includes(user.id)}
                        disabled={user.status !== SystemConstant.BRANCH_REQUEST_STATUS.REJECTED.status}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <Typography className={classes.userName}>{user.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">{user.phone || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                    <TableCell align="left">{user.email || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                    <TableCell align="left">{user.note || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                    <TableCell align="center">
                      <BranchRequestStatus user={user} refetch={refetch} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <small>{getLabel(LangConstant.TXT_NOT_FOUND)}</small>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pagination.total_pages > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
          <Pagination
            count={pagination.total_pages}
            onChange={onChangePage}
            page={pagination.offset / pagination.limit + 1}
            className={classes.pagination}
            color="primary"
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  );
};

export default BranchRequestListTable;

const useStyles = makeStyles(() => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
  },

  userImage: {
    width: "30px",
    height: "30px",
    position: "static",
  },

  userName: {
    fontSize: "14px",
  },

  inviteAgain: {
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 600,
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginRight: 16,
  },
}));
