import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import HumpUtils from "humps";
import { ApiConstant, KeyConstant, LangConstant } from "const";
import { ApiUtils, StorageUtils, SystemUtils } from "utils";

export default function useUnitDetails() {
  const { t: getLabel } = useTranslation();

  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const unitIdParam = searchParams.get("unit_id") || "";

  const [unitId, setUnitId] = useState(unitIdParam);
  const [unit, setUnit] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);

  const positionListQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getPositionListByUnitId, unitId],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const paging = 1; // 1 for pagination | 0 for get all
      const limit = 10000;
      const filter = JSON.stringify(HumpUtils.decamelizeKeys({ positionName: "", unitId: unit.unitId, departmentId: "" }));
      const asc = 0;
      const offset = 0;
      const orderBy = "created";
      const payload = { branchId, paging, limit, filter, asc, offset, orderBy };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_LIST_POSITIONS,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onSuccess: response => {
      setPositions(response?.data?.data || []);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const departmentListQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getDepartmentList, unitId],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const paging = 1; // 1 for pagination | 0 for get all
      const limit = 10000;
      const asc = 1;
      const offset = 0;
      const payload = { branchId, unitId, paging, limit, asc, offset };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_DEPARTMENT_LIST,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onSuccess: response => {
      setDepartments(response.data.data);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const unitDetailQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getUnitDetails],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = { branchId, unitId };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_UNIT_DETAILS,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onSuccess: response => {
      setUnit(response.data);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const handleGetUnitDetails = id => {
    setUnitId(id);
  };

  useEffect(() => {
    if (unitId) unitDetailQuery.refetch();
  }, [unitId]);

  useEffect(() => {
    if (unit) {
      departmentListQuery.refetch();
      positionListQuery.refetch();
    }
  }, [unit]);

  return {
    unit,
    departments,
    positions,
    handleGetUnitDetails,
  };
}
