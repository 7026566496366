import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";
import qs from "qs";

export default function useBranchRequestUpdate() {
  const { t: getLabel } = useTranslation();

  const updateBranchRequestMutation = useMutation({
    mutationFn: async payload => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.POST_BRANCH_REQUEST_UPDATE,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose, refetch }) => {
      if (response.status === ApiConstant.STT_OK) {
        onClose();
        refetch();
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_SUCCESS), "success");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const updateBranchRequest = payload => {
    updateBranchRequestMutation.mutate(payload);
  };

  const updateBranchRequestAsync = async payload => {
    return updateBranchRequestMutation.mutateAsync(payload);
  };

  return { updateBranchRequest, updateBranchRequestAsync };
}
