import { AppConstant, KeyConstant, SystemConstant } from "const";
import { ApiUtils, StorageUtils } from "utils";

export default function usePreAuth() {
  const initSmartOTPOptions = loginData => {
    const maxRetry = SystemConstant.SMART_OTP_CONFIGS.maxRetry;
    const defaultConfigs = { code: null, retry: maxRetry, otpBlockedAt: null, otpSentAt: Date.now() };
    const currentConfigs = StorageUtils.getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    StorageUtils.removeStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    if (!currentConfigs) {
      // Init smart OTP configs for new device
      const initSmartOtpConf = { [loginData.username]: { ...defaultConfigs } };
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, initSmartOtpConf, loginData.remember);
    } else if (currentConfigs && !currentConfigs[loginData.username]) {
      // Init smart OTP configs for new user
      currentConfigs[loginData.username] = { ...defaultConfigs };
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, currentConfigs, loginData.remember);
    } else if (currentConfigs && currentConfigs[loginData.username]) {
      // Re-init smart OTP configs for existed user
      currentConfigs[loginData.username] = { ...currentConfigs[loginData.username], code: null, retry: maxRetry };
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, currentConfigs, loginData.remember);
    }
  };

  const hashPassword = loginData => {
    return window.btoa(loginData.password);
  };

  const persistPreAuthConfigs = (loginData, hashPassword) => {
    const preAuthConf = { username: loginData.username, password: hashPassword, remember: loginData.remember };
    StorageUtils.storeData(KeyConstant.KEY_PRE_AUTH, preAuthConf, loginData.remember);
  };

  const persistFirstLoginConfigs = (response, loginData, hashPassword) => {
    if (response.data.first_login)
      StorageUtils.storeData(KeyConstant.KEY_OLD_PASSWORD, hashPassword, loginData.remember);
  };

  const persistLoginConfigs = (defaultBranch, loginData, response, branchData, role) => {
    StorageUtils.storeData(KeyConstant.KEY_ACCESS_TOKEN, defaultBranch.access_token, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_REMEMBER_KEY, loginData.remember, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_USER_ID, response.data.user_id, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_USERNAME, loginData.username, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_FIRST_LOGIN, Number(response.data.first_login), loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_BRANCH_LIST, branchData, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_CURRENT_DOMAIN, defaultBranch.domain, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_BRANCH_MODE, defaultBranch.branch_mode, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_LOGIN_MODE, defaultBranch.login_mode, loginData.remember);
    StorageUtils.storeData(KeyConstant.KEY_ADMIN_ROLE, role, loginData.remember);
  };

  const handleRootRoleLoginSuccess = async (response, loginData) => {
    // If user role === ROOT => SKIP verify smart OTP
    const branchData = Array.isArray(response.data.data)
      ? response.data.data.map(item => {
          StorageUtils.storePermanenceData(item.id, item.options);
          delete item.options;
          return item;
        })
      : [];
    let defaultBranch = branchData.find(item => item.domain === AppConstant.DEFAULT_DOMAIN);
    if (!Boolean(defaultBranch)) defaultBranch = branchData[0];
    persistLoginConfigs(defaultBranch, loginData, response, branchData, SystemConstant.LOGIN_ROLE.ROOT);
    const maxRetry = SystemConstant.SMART_OTP_CONFIGS.maxRetry;
    const smartOtpData = StorageUtils.getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    if (smartOtpData && smartOtpData[loginData.username]) {
      smartOtpData[loginData.username] = { code: "000000", retry: maxRetry, otpBlockedAt: null, otpSentAt: Date.now() };
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, smartOtpData, loginData.remember);
    }
    await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.LOGIN);
  };

  const handleAdminRoleLoginSuccess = (response, loginData) => {
    // If user role === ADMIN => NEED verify smart OTP
    const smartOtpData = StorageUtils.getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    if (smartOtpData && smartOtpData[loginData.username]) {
      smartOtpData[loginData.username] = {
        ...smartOtpData[loginData.username],
        code: null,
        otpBlockedAt: null,
        otpSentAt: Date.now(),
        retry:
          smartOtpData[loginData.username].retry === 0
            ? SystemConstant.SMART_OTP_CONFIGS.maxRetry
            : smartOtpData[loginData.username].retry,
      };
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, smartOtpData, loginData.remember);
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_REMOTE_CODE, response.status, false);
    }
  };

  const handleResendOTPGotLimited = (response, loginData) => {
    // Check if the user resend OTP before 60 seconds have elapsed
    const smartOtpData = StorageUtils.getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    if (smartOtpData && smartOtpData[loginData.username]) {
      smartOtpData[loginData.username] = {
        ...smartOtpData[loginData.username],
        code: null,
        otpBlockedAt: null,
      };
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, smartOtpData, loginData.remember);
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_REMOTE_CODE, response.status, false);
    }
  };

  const handleOTPBlocked = (response, loginData) => {
    // Check if users are blocked because they entered the wrong OTP many times
    const smartOtpData = StorageUtils.getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    if (smartOtpData && smartOtpData[loginData.username]) {
      smartOtpData[loginData.username] = {
        ...smartOtpData[loginData.username],
        code: null,
        retry: 0,
        otpBlockedAt: smartOtpData[loginData.username].otpBlockedAt
          ? smartOtpData[loginData.username].otpBlockedAt
          : Date.now(),
      };
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, smartOtpData, loginData.remember);
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_REMOTE_CODE, response.status, false);
    }
  };

  const handleWrongUsernamePassword = loginData => {
    // Wrong username or password
    StorageUtils.removeStoreData(KeyConstant.KEY_PRE_AUTH);
    const smartOtpData = StorageUtils.getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    if (smartOtpData && smartOtpData[loginData.username]) {
      delete smartOtpData[loginData.username];
      StorageUtils.storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, smartOtpData, loginData.remember);
    }
  };

  return {
    initSmartOTPOptions,
    hashPassword,
    persistPreAuthConfigs,
    persistFirstLoginConfigs,
    persistLoginConfigs,
    handleRootRoleLoginSuccess,
    handleAdminRoleLoginSuccess,
    handleResendOTPGotLimited,
    handleOTPBlocked,
    handleWrongUsernamePassword,
  };
}
