import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseOutlined } from "@mui/icons-material";
import { LangConstant, KeyConstant } from "const";
import { StorageUtils } from "utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useGetBranchQR, useBranchDetails } from "hooks";

const CreateQRDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const { getBranchQR } = useGetBranchQR();

  const [currentBranchExpiryTime, setCurrentBranchExpiryTime] = useState(
    StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_QR_EXPIRY_TIME),
  );
  const [time, setTime] = useState(null);
  const [dataQR, setDataQR] = useState(null);

  const handleSubmit = async () => {
    if (time) {
      const qr = await getBranchQR({ qr_expiry_time: time.getTime() });
      setDataQR(qr);
      StorageUtils.storeData(KeyConstant.KEY_BRANCH_QR_EXPIRY_TIME, time.getTime());
      setCurrentBranchExpiryTime(time.getTime());
    }
  };

  const downloadQR = () => {
    const link = document.createElement("a");
    link.href = dataQR;
    link.download = "qr.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (currentBranchExpiryTime) {
      if (currentBranchExpiryTime <= Date.now()) {
        const today = new Date();
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + 7);
        setTime(futureDate);
      } else {
        setTime(new Date(currentBranchExpiryTime));
      }
    }
  }, [currentBranchExpiryTime]);

  useEffect(async () => {
    if (!time && currentBranchExpiryTime && currentBranchExpiryTime >= Date.now()) {
      const qr = await getBranchQR({ qr_expiry_time: currentBranchExpiryTime });
      setDataQR(qr);
    }
  }, [currentBranchExpiryTime]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Box sx={{ display: "flex", justifyContent: "flex-end", height: "40px", backgroundColor: "#F2F6FC" }}>
        <Button onClick={onClose}>
          <CloseOutlined fill="black" />
        </Button>
      </Box>

      <DialogContent sx={{ paddingTop: "6px" }}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "50%",
              height: "auto",
              backgroundColor: "#DFDFDF",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!Boolean(dataQR) ? (
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Nhấn “Tạo mã QR” để tạo mã QR</Typography>
            ) : (
              <Box component="img" sx={{ width: "100%" }} src={dataQR} />
            )}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "16px", marginLeft: "8px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Hạn sử dụng"
                value={time}
                format="dd/MM/yyyy"
                onChange={newValue => setTime(newValue)}
                minDate={Date.now() + 24 * 60 * 60 * 1000}
              />
            </LocalizationProvider>

            <Button
              sx={{ marginTop: "40%" }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!Boolean(time) || time.getTime() === currentBranchExpiryTime}
            >
              Tạo mã QR
            </Button>
            <Button
              sx={{ marginTop: "8px" }}
              variant="contained"
              color="primary"
              onClick={downloadQR}
              disabled={!Boolean(dataQR) || !Boolean(time) || time.getTime() !== currentBranchExpiryTime}
            >
              Lưu mã QR
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateQRDialog;

const useStyles = makeStyles(() => ({
  form: {
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },

  textArea: {
    boxSizing: "border-box",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "8px 12px",
    borderRadius: "8px",
    boxShadow: "0 0 5px 2px",

    "&:hover": {
      boxShadow: "0 0 5px 3px cornflowerblue",
    },
  },
}));
