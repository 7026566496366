import React, { memo, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import localforage from "localforage";
import { Box, Button, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Sync, Analytics, Portrait, SpeakerNotesOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { AppConstant, ImageConstant, KeyConstant, LangConstant, PathConstant } from "const";
import { AuthUtils, EventBusUtils, StorageUtils } from "utils";

const Sidebar = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  const sidebarTitles = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  const [branchAvatar, setBranchAvatar] = useState("");

  const sidebarItems = [
    {
      href: PathConstant.COMPANY_MANAGEMENT,
      icon: ImageConstant.CompanyManagementIcon,
      title: sidebarTitles.companyManagement,
    },
    {
      href: PathConstant.POSITION_MANAGEMENT,
      icon: Portrait,
      title: sidebarTitles.positionManagement,
    },
    {
      href: PathConstant.MEDIA_MANAGEMENT,
      icon: SpeakerNotesOutlined,
      title: sidebarTitles.mediaManagement,
    },
    {
      href: PathConstant.USER_MANAGEMENT,
      icon: ImageConstant.UserManagementIcon,
      title: sidebarTitles.userManagement,
    },
    {
      href: PathConstant.LICENSE,
      icon: ImageConstant.LicenseIcon,
      title: sidebarTitles.license,
    },
    {
      href: PathConstant.SYSTEM_TRACKING,
      icon: Analytics,
      title: sidebarTitles.systemTracking,
    },
    {
      href: PathConstant.SETTINGS,
      icon: ImageConstant.SettingIcon,
      title: sidebarTitles.settings,
    },
    {
      href: PathConstant.SERVER_MONITORING,
      icon: ImageConstant.MonitoringIcon,
      title: sidebarTitles.serverMonitoring,
    },
  ];

  const redirect = href => {
    if (href) history.push(href);
  };

  const handleGetBranchAvatar = avatar => {
    setBranchAvatar(avatar);
  };

  useEffect(() => {
    EventBusUtils.on(AppConstant.EVENT_BUS_EVENTS.fetchBranchDetails, handleGetBranchAvatar);
    return () => EventBusUtils.off(AppConstant.EVENT_BUS_EVENTS.fetchBranchDetails, handleGetBranchAvatar);
  });

  useEffect(() => {
    const branchAttachmentId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ATTACHMENT);
    localforage.getItem(KeyConstant.KEY_CACHED_BRANCH_AVATAR_BLOB + branchAttachmentId).then(avatar => {
      setBranchAvatar(URL.createObjectURL(avatar));
    });
  }, []);

  return (
    <Box>
      <Box className={classes.triosLogoContainer}>
        <Box component="img" className={classes.triosLogo} src={branchAvatar || ImageConstant.TriosLogo} />
      </Box>
      <List className={classes.sidebarContainer}>
        {sidebarItems.map((sidebar, index) => {
          const Icon = sidebar.icon;
          return (
            <ListItemButton
              key={index}
              className={classes.sidebarItem}
              selected={location.pathname === sidebar.href}
              onClick={() => redirect(sidebar.href)}
              disableTouchRipple
            >
              <ListItemIcon className={classes.sidebarIcon}>
                <Icon
                  className={clsx({
                    [classes.sidebarIconActive]: location.pathname === sidebar.href,
                  })}
                />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: location.pathname === sidebar.href ? classes.sidebarTitleActive : classes.sidebarTitle,
                }}
                primary={sidebar.title}
              />
            </ListItemButton>
          );
        })}
        <ListItemButton className={classes.sidebarItem} onClick={AuthUtils.handleLogout} disableTouchRipple>
          <ListItemIcon className={classes.sidebarIcon}>
            <ImageConstant.LogoutIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.sidebarTitle,
            }}
            primary={sidebarTitles.logout}
          />
        </ListItemButton>
      </List>
      <Box className={classes.footer}>
        <Box className={classes.switchBranchBox}>
          <Button
            variant="contained"
            className={classes.switchButton}
            startIcon={<Sync />}
            href={PathConstant.BRANCH_MANAGEMENT}
          >
            {getLabel(LangConstant.L_SWITCH_BRANCH)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Sidebar);

const useStyles = makeStyles(theme => ({
  sidebarContainer: {
    height: "calc(100vh - 160px - 80px)",
    overflowY: "scroll",
    padding: 0,
  },

  sidebarItem: {
    margin: "8px 16px 8px",
    borderRadius: 4,
  },

  sidebarTitleActive: {
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },

  sidebarIcon: {
    minWidth: 36,
  },

  sidebarIconActive: {
    color: theme.palette.primary.main,
  },

  sidebarTitle: {
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 600,
    color: "#818194",
  },

  triosLogoContainer: {
    width: "100%",
    height: "160px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  triosLogo: {
    backgroundSize: "contain",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
  },

  switchBranchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  footer: {
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #ddd",
  },
}));
