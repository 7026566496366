import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, BranchUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useUpdateBranch() {
  const { t: getLabel } = useTranslation();
  const { invalidateBranchQueries } = useInvalidateQueries();

  const changeBranchStatusMutation = useMutation({
    mutationFn: async data => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.UPDATE_BRANCH,
        qs.stringify(HumpUtils.decamelizeKeys(data)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, data) => {
      if (response.status === ApiConstant.STT_OK) {
        const isRemember = StorageUtils.getStoreData(KeyConstant.KEY_REMEMBER_KEY);
        const branchList = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST);
        let tmpBranchList = branchList.filter(item => item.id !== data.id);
        tmpBranchList = BranchUtils.sortBranchByName([
          ...tmpBranchList,
          HumpUtils.decamelizeKeys({ ...data, ...response.data }),
        ]);
        StorageUtils.storeData(KeyConstant.KEY_BRANCH_LIST, tmpBranchList, isRemember);
        await invalidateBranchQueries();
        await ApiUtils.apiCreateUserActivitiesLog(
          data.state
            ? SystemConstant.LOGGER_ACTION_TYPES.ACTIVATE_BRANCH
            : SystemConstant.LOGGER_ACTION_TYPES.DEACTIVATE_BRANCH,
        );
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const updateBranchMutation = useMutation({
    mutationFn: async data => {
      const payload = {
        id: data.id,
        attachmentId: data.attachmentId,
        name: data.name,
        loginMode: data.loginMode,
        branchMode: data.branchMode,
        information: data.information,
      };
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.UPDATE_BRANCH,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, data) => {
      if (response.status === ApiConstant.STT_OK) {
        const isRemember = StorageUtils.getStoreData(KeyConstant.KEY_REMEMBER_KEY);
        if (data.isUpdateBranchConfigs) {
          StorageUtils.storeData(KeyConstant.KEY_BRANCH_MODE, data.branchMode, isRemember);
          StorageUtils.storeData(KeyConstant.KEY_LOGIN_MODE, data.loginMode, isRemember);
          await ApiUtils.apiCreateUserActivitiesLog(
            SystemConstant.LOGGER_ACTION_TYPES.UPDATE_BRANCH_CONFIGS,
            StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
          );
        } else {
          await ApiUtils.apiCreateUserActivitiesLog(
            SystemConstant.LOGGER_ACTION_TYPES.UPDATE_BRANCH_INFORMATION,
            StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
          );
        }
        const branchList = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST);
        let tmpBranchList = branchList.filter(item => item.id !== data.id);
        tmpBranchList = BranchUtils.sortBranchByName([
          ...tmpBranchList,
          HumpUtils.decamelizeKeys({ ...data, ...response.data }),
        ]);
        StorageUtils.storeData(KeyConstant.KEY_BRANCH_LIST, tmpBranchList, isRemember);
        await invalidateBranchQueries();
        await ApiUtils.createApiWithToken(false).get(ApiConstant.BRANCH_INFORMATION);
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const changeBranchStatus = payload => {
    changeBranchStatusMutation.mutate(payload);
  };

  const updateBranchInfo = payload => {
    updateBranchMutation.mutate(payload);
  };

  return { changeBranchStatus, updateBranchInfo };
}
