import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { FeedOutlined, QrCodeOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { KeyConstant, LangConstant } from "const";
import { BranchUtils, StorageUtils, SystemUtils } from "utils";
import { useUpdateBranch } from "hooks";
import { PaperHeader } from "components";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import CreateQRDialog from "./CreateQRDialog";
import PreviewInformationDialog from "./PreviewInformationDialog";

const ServerInformation = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { updateBranchInfo } = useUpdateBranch();

  const currentBranchInformation = StorageUtils.getPermanenceData(KeyConstant.KEY_BRANCH_INFORMATION);
  const currentBranchExpiryTime = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_QR_EXPIRY_TIME);

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["link", "image", "video"],
        [{ color: [] }],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  });

  const [contentInformation, setContentInformation] = useState(currentBranchInformation);
  const [msgQR, setMsgQR] = useState("");
  const [isQRExpired, setIsQRExpired] = useState(false);
  const [isOpenQRDialog, setIsOpenQRDialog] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    const currentBranchDetails = BranchUtils.getCurrentBranch();
    const payload = { ...currentBranchDetails, information: contentInformation, isUpdateBranchConfigs: false };
    updateBranchInfo(payload);
  };

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(contentInformation, "silent");
      quill.getModule("toolbar").addHandler("image", () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            const fileSize = file.size;
            if (fileSize > 50 * 1024) {
              SystemUtils.appendNotification("Ảnh vượt quá kích thước cho phép", "error");
              return;
            } else {
              const reader = new FileReader();
              reader.onload = e => {
                const range = quill.getSelection();
                quill.insertEmbed(range.index, "image", e.target.result);
              };
              reader.readAsDataURL(file);
            }
          }
        };
      });

      quill.on("text-change", (delta, oldDelta, source) => {
        setContentInformation(quillRef.current.firstChild.innerHTML);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (currentBranchExpiryTime) {
      if (currentBranchExpiryTime <= Date.now()) {
        setMsgQR("Mã QR đã hết hạn sử dụng, xin vui lòng làm mới");
        setIsQRExpired(true);
      } else {
        const date = new Date(currentBranchExpiryTime);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        setMsgQR(`Mã QR có thời hạn đến hết ${day}/${month}/${year}`);
        setIsQRExpired(false);
      }
    }
  }, [currentBranchExpiryTime]);

  return (
    <Box className={classes.box}>
      <PaperHeader icon={<FeedOutlined className={classes.icon} />} title="Thông tin server" />
      <Box>
        <Typography className={classes.modeTitle}> Nội dung </Typography>

        <Box sx={{ width: "100%", height: "200px" }}>
          <Box ref={quillRef} />
        </Box>
      </Box>
      <Box className={classes.btnPreview} onClick={() => setIsOpenPreview(true)}>
        <Typography
          sx={{
            cursor: "pointer",
            color: "#008FE8",
            "&:hover": {
              boxShadow: "0 0 5px 3px cornflowerblue",
            },
          }}
        >
          {" "}
          Xem trước{" "}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: "16px" }}>
        <Typography className={classes.modeTitle}> Mã QR server</Typography>
        <Button variant="contained" disableElevation onClick={() => setIsOpenQRDialog(true)}>
          <QrCodeOutlined /> Tạo mã QR
        </Button>
        <Typography sx={isQRExpired ? { color: "red" } : { color: "cornflowerblue" }}> {msgQR} </Typography>
      </Box>

      <Box textAlign="center">
        <Button className={classes.button} size="large" variant="contained" disableElevation onClick={handleSubmit}>
          {getLabel(LangConstant.L_SAVE_BUTTON)}
        </Button>
      </Box>

      <PreviewInformationDialog open={isOpenPreview} onClose={() => setIsOpenPreview(false)} contentInformation={contentInformation} />

      <CreateQRDialog open={isOpenQRDialog} onClose={() => setIsOpenQRDialog(false)} />
    </Box>
  );
};

export default ServerInformation;

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "28px 36px",
    marginBottom: 16,
  },

  boxHeader: {
    borderBottom: "2px solid #E4E4E4",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },

  boxHeaderItem: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 23,
  },

  statusTitle: {
    color: "#CBCACA",
    fontSize: 15,
    lineHeight: "20px",
    marginRight: 10,
  },

  firstBox: {
    marginTop: 45,
  },

  icon: {
    width: 36,
    height: 23,
  },

  rootForm: {
    width: "100%",
    margin: "20px auto 0",
  },

  formControlLabelRoot: {
    width: "100%",
    margin: "0 0 16px",
    alignItems: "flex-start",
  },

  formControlLabel: {
    marginBottom: 10,
    fontSize: 15,
    lineHeight: "20px",
  },

  radioLabel: {
    marginBottom: 0,
  },

  formControl: {
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    fontSize: 15,
    padding: "8px 16px",
    width: "100%",
    "& ::placeholder": {
      color: "#CBCACA",
    },
  },

  button: {
    minHeight: 46,
    minWidth: 345,
    borderRadius: 4,
    fontSize: 15,
    lineHeight: "20px",
  },

  modeTitle: {
    fontSize: 16,
    marginBottom: 8,
    fontWeight: 600,
  },

  valueGroup: {
    marginBottom: 20,
  },

  btnPreview: {
    height: "40px",
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
    alignItems: "flex-end",
    marginTop: "44px",
  },
}));
