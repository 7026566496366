import { ApiConstant, PathConstant, SystemConstant } from "const";

const vi = {
  login: {
    // TEXTS
    TXT_LOGIN: "Đăng nhập với quyền quản trị viên",
    TXT_CHANGE_INFO: "Thay đổi thông tin",
    TXT_ADD_AVATAR: "Thêm logo của công ty",

    // LABELS
    L_USERNAME: "Tài khoản",
    L_PASSWORD: "Mật khẩu",
    L_REMEMBER_ME: "Lưu đăng nhập",
    L_LOGIN_BUTTON: "Đăng nhập",
    L_NEW_PASSWORD: "Nhập mật khẩu của bạn",
    L_NEW_PASSWORD_CONFIRMATION: "Xác nhận mật khẩu",
    L_CONTINUE_BUTTON: "Tiếp tục",

    // PLACEHOLDERS
    P_USERNAME: "Nhập tài khoản của bạn",
    P_PASSWORD: "Nhập mật khẩu của bạn",
    P_NEW_PASSWORD: "Nhập mật khẩu của bạn",

    // ERROR OBJECT
    ERR_OBJECT: {
      [ApiConstant.STT_UNAUTHORIZED]: "Sai tài khoản hoặc mật khẩu. Vui lòng thử lại!",
      [ApiConstant.STT_NOT_FOUND]: "Sai tài khoản hoặc mật khẩu. Vui lòng thử lại!",
      passwordConfirmationNotMatch: "Trường xác nhận mật khẩu và mật khẩu không khớp",
      imageNotValid: "Vui lòng chọn ảnh định dạng PNG hoặc JPG",
      somethingWrong: "Đã có lỗi xảy ra, vui lòng thử lại sau",
    },
  },

  common: {
    // OBJECTS
    OBJ_ROUTER_TITLE: {
      [PathConstant.LOGIN]: "Đăng nhập với quyền quản trị viên",
      [PathConstant.FIRST_LOGIN]: "Thay đổi thông tin",
      [PathConstant.COMPANY_MANAGEMENT]: "Quản lý tổ chức",
      [PathConstant.USER_MANAGEMENT]: "Quản lý người dùng",
      [PathConstant.LICENSE]: "Bản quyền",
      [PathConstant.SETTINGS]: "Cài đặt",
      [PathConstant.SYSTEM_TRACKING]: "Theo dõi hệ thống",
      [PathConstant.BRANCH_MANAGEMENT]: "Quản lý branch server",
      [PathConstant.VERIFY_SMART_OTP]: "Xác thực bằng SMART OTP",
      [PathConstant.POSITION_MANAGEMENT]: "Quản lý vị trí",
      [PathConstant.MEDIA_MANAGEMENT]: "Quản lý tin truyền thông",
    },

    OBJ_SIDEBAR_TITLE: {
      companyManagement: "Quản lý tổ chức",
      userManagement: "Quản lý người dùng",
      license: "Bản quyền",
      settings: "Cài đặt",
      logout: "Đăng xuất",
      systemTracking: "Theo dõi hệ thống",
      branchManagement: "Quản lý Branch Server",
      serverMonitoring: "Giám sát máy chủ",
      positionManagement: "Quản lý vị trí",
      mediaManagement: "Quản lý tin truyền thông",
    },

    OBJ_ERROR_MSG: {
      wrongPassword: "Mật khẩu không chính xác! Xin vui lòng thử lại",
      passwordConfirmationNotMatch: "Trường xác nhận mật khẩu và mật khẩu mới không khớp",
    },

    OBJ_USER_DETAIL: {
      userName: "Tên người dùng",
      fullName: "Họ và tên",
      phone: "Số điện thoại",
      email: "Email",
      birthday: "Ngày sinh",
      activeStatus: "Trạng thái hoạt động",
      joinStatus: "Trạng thái tham gia",
      unit: "Đơn vị",
      department: "Phòng ban",
    },

    OBJ_BRANCH_ERROR: {
      updateTime: "Thời gian cập nhật",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      search: "Tra cứu",
      branchServer: "Branch Server",
    },

    OBJ_TABLE_ERROR: {
      number: "STT",
      errorFeature: "Chức năng gặp vấn đề",
      errorComponent: "Thành phần gặp vấn đề",
      time: "Thời gian",
      description: "Mô tả",
      rowsPerPage: "Số bản ghi hiển thị",
    },

    OBJ_TABLE_USER_ACTIVITIES: {
      number: "STT",
      user: "ID Người dùng",
      action: "Hành động",
      time: "Thời gian",
      ipAddress: "Địa chỉ IP",
      rowsPerPage: "Số bản ghi hiển thị",
    },

    OBJ_TABLE_BRANCH: {
      number: "STT",
      avatar: "Ảnh đại diện",
      branchName: "Tên Branch Server",
      package: "Package",
      packageId: "Package ID",
      license: "Số Giấy Phép",
      existAccount: "Số tài khoản đăng ký",
      status: "Trạng thái",
      action: "Hành động",
    },

    OBJ_CONFIRM_RESET: {
      title: "Đặt lại mật khẩu",
      content: "Bạn muốn đặt lại mật khẩu cho người dùng đã chọn không ?",
      acceptButton: "Đồng ý",
      cancelButton: "Hủy bỏ",
    },

    OBJ_BRANCH_ERROR_TYPE: {
      sendMessage: "Gửi tin nhắn",
      audioCall: "Gọi đơn",
      videoCall: "Gọi video đơn",
      audioGroupCall: "Gọi nhóm",
      videoGroupCall: "Gọi video nhóm",
      updateProfile: "Cập nhật hồ sơ",
    },

    OBJ_RANDOM_ADMIN_PASSWORD: {
      title: "Mật khẩu mới",
      content: "Mật khẩu mới của quản trị viên là: {{password}}",
      close: "Đóng",
      copy: "Sao chép mật khẩu",
    },

    OBJ_USER_ACTIVITIES: {
      [SystemConstant.LOGGER_ACTION_TYPES.LOGIN]: "Đăng nhập",
      [SystemConstant.LOGGER_ACTION_TYPES.LOGOUT]: "Đăng xuất",
      [SystemConstant.LOGGER_ACTION_TYPES.UPDATE_BRANCH_INFORMATION]: "Cập nhật thông tin branch",
      [SystemConstant.LOGGER_ACTION_TYPES.ADD_NEW_USERS]: "Thêm mới người dùng vào branch",
      [SystemConstant.LOGGER_ACTION_TYPES.ACTIVE_USER]: "Kích hoạt người dùng",
      [SystemConstant.LOGGER_ACTION_TYPES.BLOCK_USER]: "Vô hiệu hóa người dùng",
      [SystemConstant.LOGGER_ACTION_TYPES.UPDATE_USER_INFORMATION]: "Cập nhật thông tin người dùng",
      [SystemConstant.LOGGER_ACTION_TYPES.RESET_USER_PASSWORD]: "Đặt lại mật khẩu người dùng",
      [SystemConstant.LOGGER_ACTION_TYPES.CHANGE_PASSWORD]: "Đổi mật khẩu",
      [SystemConstant.LOGGER_ACTION_TYPES.UPDATE_BRANCH_CONFIGS]: "Cập nhật cấu hình branch",
      [SystemConstant.LOGGER_ACTION_TYPES.IMPORT_NEW_USERS_FROM_EXCEL]: "Thêm mới người dùng bằng file Excel",
      [SystemConstant.LOGGER_ACTION_TYPES.ADD_BRANCH]: "Thêm branch server",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_BRANCH]: "Xóa branch serve",
      [SystemConstant.LOGGER_ACTION_TYPES.ACTIVATE_BRANCH]: "Kích hoạt branch",
      [SystemConstant.LOGGER_ACTION_TYPES.DEACTIVATE_BRANCH]: "Vô hiệu hóa branch",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_UNIT]: "Thêm đơn vị",
      [SystemConstant.LOGGER_ACTION_TYPES.EDIT_UNIT]: "Cập nhật đơn vị",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_UNIT]: "Xóa đơn vị",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_DEPARTMENT]: "Thêm phòng ban",
      [SystemConstant.LOGGER_ACTION_TYPES.EDIT_DEPARTMENT]: "Cập nhật phòng ban",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_DEPARTMENT]: "Xóa phòng ban",
      [SystemConstant.LOGGER_ACTION_TYPES.ADD_DEPARTMENT_USERS]: "Thêm thành viên phòng ban",
      [SystemConstant.LOGGER_ACTION_TYPES.REMOVE_DEPARTMENT_USERS]: "Xóa thành viên phòng ban",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_UNIT_CHANNEL]: "Tạo kênh đơn vị",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_DEPARTMENT_CHANNEL]: "Tạo kênh phòng ban",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_POSITION]: "Thêm vị trí",
      [SystemConstant.LOGGER_ACTION_TYPES.EDIT_POSITION]: "Cập nhật vị trí",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_POSITION]: "Xóa vị trí",
    },

    OBJ_USER_FILTER_STATUS: {
      inactive: "Đang chờ",
      active: "Đã tham gia",
      all: "Tất cả",
      pending: "Chưa đăng nhập hệ thống",
    },

    OBJ_UNIT_LIST_TABLE: {
      name: "Tên đơn vị/phòng ban",
      departmentNumber: "Số phòng ban",
      employeeNumber: "Số nhân sự",
    },

    OBJ_UNIT_DETAIL: {
      name: "Tên đơn vị",
      branch: "Trực thuộc",
    },

    OBJ_UNIT_ERROR: {
      [SystemConstant.UNIT_ERROR.MISSING_PARAMETERS]: "Dữ liệu truyền lên thiếu",
      [SystemConstant.UNIT_ERROR.IN_WRONG_FORMAT]: "Tên đơn vị không đúng định dạng",
      [SystemConstant.UNIT_ERROR.DUPLICATED]: "Đơn vị đã tồn tại",
      [SystemConstant.UNIT_ERROR.CONTAIN_DEPARTMENTS]: "Không thể xóa đơn vị này, đơn vị đang có phòng ban trực thuộc",
    },

    OBJ_DEPARTMENT_DETAIL: {
      name: "Tên phòng ban",
      unit: "Trực thuộc đơn vị",
    },

    OBJ_DEPARTMENT_ERROR: {
      [SystemConstant.DEPARTMENT_ERROR.MISSING_PARAMETERS]: "Dữ liệu truyền lên thiếu",
      [SystemConstant.DEPARTMENT_ERROR.IN_WRONG_FORMAT]: "Tên phòng ban không đúng định dạng",
      [SystemConstant.DEPARTMENT_ERROR.DUPLICATED]: "Phòng ban đã tồn tại",
      [SystemConstant.DEPARTMENT_ERROR.CHANNEL_DUPLICATED]: "Kênh của phòng ban này đã có",
      [SystemConstant.DEPARTMENT_ERROR.CHANNEL_MISSING_PARAMETERS]: "DỮ liệu tạo kênh thiếu",
    },

    OBJ_POSITION_ERROR: {
      [SystemConstant.DEPARTMENT_ERROR.MISSING_PARAMETERS]: "Dữ liệu truyền lên thiếu",
      [SystemConstant.DEPARTMENT_ERROR.IN_WRONG_FORMAT]: "Tên vị trí không đúng định dạng",
      [SystemConstant.DEPARTMENT_ERROR.DUPLICATED]: "Tên vị trí đã tồn tại",
    },

    OBJ_CHANNEL_DIALOG: {
      unitTitle: "Tạo kênh đơn vị",
      departmentTitle: "Tạo kênh phòng DA",
      channelName: "Tên kênh",
      channelMode: "Chế độ kênh",
      channelManager: "Chọn quản lý kênh",
      unitConfirm: "Bạn chắc chắn thực hiện tạo kênh cho đơn vị này",
      departmentConfirm: "Bạn chắc chắn thực hiện tạo kênh cho phòng ban này?",
      noUser: "Không có người dùng",
    },

    // LABELS
    L_NEED_HELP_BUTTON: "Trợ giúp",
    L_COMPANY_NAME: "Tên tổ chức",
    L_COMPANY_AVATAR: "Ảnh đại diện",
    L_CHANGE_COMPANY_AVATAR_BUTTON: "Thay đổi",
    L_CONFIRM_BUTTON: "Xác nhận",
    L_DISCARD_BUTTON: "Huỷ",
    L_DELETE_USER_BUTTON: "Xoá",
    L_ADD_NEW_USER_BUTTON: "Thêm mới",
    L_JOINED_STATUS: "Đã tham gia",
    L_PENDING_STATUS: "Đang chờ",
    L_REFUSED_STATUS: "Đã từ chối",
    L_REMOVE_BUTTON: "Vô hiệu hóa",
    L_CANCEL_BUTTON: "Huỷ bỏ",
    L_ADD_BUTTON: "Thêm",
    L_ACTIVE: "Đã kích hoạt",
    L_ACTIVATION_STATE: "Trạng thái kích hoạt",
    L_EXPIRED: "Đã hết hạn",
    L_UPGRADE_LICENSE: "Nâng cấp giấy phép của bạn",
    L_PASSWORD_LAST_UPDATE: "Cập nhật lần cuối: {{time}}",
    L_OLD_PASSWORD: "Nhập mật khẩu cũ của bạn",
    L_UPDATE_PASSWORD_BUTTON: "Cập nhật mật khẩu",
    L_LANGUAGE: "Ngôn ngữ",
    L_VIETNAMESE_LANGUAGE: "Tiếng Việt",
    L_ENGLISH_LANGUAGE: "Tiếng Anh",
    L_SAVE_BUTTON: "Lưu thay đổi",
    L_PUBLIC_MODE: "Công khai",
    L_PRIVATE_MODE: "Riêng tư",
    L_PASSWORD_MODE: "Mật khẩu",
    L_OTP_MODE: "Mã OTP",
    L_ACTIVE_USER: "Hoạt động",
    L_INACTIVE_USER: "Ngưng hoạt động",
    L_BRANCH_LIST: "Danh sách Branch Server",
    L_OK: "OK",
    L_CREATE: "Tạo mới",
    L_SWITCH_BRANCH: "Chuyển branch",
    L_ACTIVE_ACTION: "Kích hoạt",
    L_INACTIVE_ACTION: "Vô hiệu hóa",
    L_UPDATE: "Cập nhật",
    L_AGREE: "Có",
    L_SAVE_INFO: "Lưu thông tin",
    L_CANCEL_ADD_BRANCH: "Hủy",
    L_STATUS: "Trạng thái",
    L_ACCEPT: "Đồng ý",
    L_DOWNLOAD_FILE: "Tải file",
    L_ADD_UNIT_BUTTON: "Thêm đơn vị",
    L_DELETE: "Xóa",
    L_CANCEL: "Hủy",
    L_ALL: "Tất cả",
    L_CLOSE: "Đóng",
    L_SELECT_UNIT: "Đơn vị",

    // PLACEHOLDERS
    P_COMPANY_NAME: "Nhập tên tổ chức của bạn",
    P_ADD_NEW_USER: "Tìm kiếm theo tên hoặc số điện thoại",
    P_OLD_PASSWORD: "Nhập mật khẩu cũ của bạn",
    P_SEARCH: "Tìm kiếm",
    P_SEARCH_UNIT: "Tìm kiếm theo tên đơn vị",
    P_SEARCH_POSITION: "Tìm kiếm theo tên vị trí",
    P_POSITION_NAME: "Nhập tên vị trí",
    P_SEARCH_USER_BY_NAME_PHONE_EMAIL: "Tìm kiếm theo tên, sđt, email",
    P_SEARCH_USER_BY_NAME_EMAIL: "Tìm kiếm theo tên, email",

    // TEXTS
    TXT_APP_COPYRIGHT: "Bản quyền thuộc về © bGlobal Jsc. Đã đăng ký bản quyền",
    TXT_COMPANY_PROFILE: "Hồ sơ tổ chức",
    TXT_INVITE_AGAIN: "Mời lại",
    TXT_INVITED: "Đã mời",
    TXT_CONFIRM_REMOVE_USER_HEADING: "Xác nhận vô hiệu hóa người dùng",
    TXT_ADD_NEW_USER: "Thêm người dùng mới",
    TXT_SELECTED_USER: "Người dùng đã chọn",
    TXT_CONTACT_FOUND: "Người dùng được tìm thấy",
    TXT_NOT_FOUND: "Không có dữ liệu",
    TXT_LOADING: "Đang tải dữ liệu",
    TXT_SERVER_EXPIRED: "Máy chủ đã hết hạn",
    TXT_SERVER_ACTIVE: "Máy chủ này được kích hoạt với giấy phép {{name}} cho đến ngày",
    TXT_UPGRADE_LICENSE_PHONE_NUM: "Bạn muốn nâng cấp giấy phép sử dụng? Hãy gọi vào số",
    TXT_UPDATE_PASSWORD_SUCCESS: "Đổi mật khẩu thành công",
    TXT_INFORMATION: "Chi tiết",
    TXT_EDIT_INFO: "Chỉnh sửa",
    TXT_RESET_PASSWORD: "Đặt lại mật khẩu",
    TXT_DETAILS_INFO: "Thông tin người dùng",
    TXT_MODE: "Chế độ",
    TXT_BRANCH_MODE: "Chế độ branch",
    TXT_LOGIN_MODE: "Chế độ đăng nhập",
    TXT_ADD_BRANCH: "Thêm mới Branch",
    TXT_CONFIRM_DELETE_BRANCH: "Bạn có chắc chắn muốn xóa branch?",
    TXT_NOTIFICATION_ERROR_TITLE: "Lỗi",
    TXT_NOTIFICATION_ERROR_CONTENT: "Thông tin không hợp lệ",
    TXT_CONFIRM_ACTIVE_USER: "Xác nhận kích hoạt người dùng",
    TXT_DELETE_FAIL: "Có lỗi xảy ra. Không thể xóa Branch",
    TXT_INACTIVE_ERROR: "Người dùng bạn chọn đã ở trạng thái Vô hiệu hóa. Vui lòng chọn người dùng khác.",
    TXT_ACTIVE_ERROR: "Người dùng bạn chọn đã ở trạng thái Hoạt động. Vui lòng chọn người dùng khác.",
    TXT_UPDATE_ERROR: "Có lỗi xảy ra trong quá trình thực hiện. Vui lòng thử lại.",
    TXT_INVALID_DATE: "Thông tin ngày không hợp lệ. Vui lòng chọn lại.",
    TXT_DATE_NULL: "Trường thông tin Từ ngày và Đến ngày không được để trống.",
    TXT_UPDATE_SUCCESS: "Cập nhập thông tin thành công.",
    TXT_EMAIL_WRONG: "Địa chỉ email không hợp lệ. Vui lòng kiểm tra lại.",
    TXT_UPLOAD_DUPLICATE_DATA: "Dữ liệu người dùng trùng lặp, hãy kiểm tra lại!",
    TXT_UPLOAD_MAX_USER_WRONG: "File sai format, hãy kiểm tra lại!",
    TXT_CONFIRM_CANCEL_CREATE_BRANCH: "Bạn có chắc chắn hủy quá trình tạo mới Branch?",
    TXT_OF: "trên",
    TXT_MORE_THAN: "lớn hơn",
    TXT_FAILURE_VALIDATION: "Dữ liệu gửi lên không hợp lệ. Vui lòng kiểm tra lại.",
    TXT_ADD_USER_SUCCESS: "Thêm mới người dùng thành công",
    TXT_SYSTEM_ERROR_LIST: "Danh sách lỗi hệ thống",
    TXT_USER_ACTIVITY_LIST: "Hoạt động của người dùng",
    TXT_VERIFY_SMART_OTP_CONTENT:
      "Chúng tôi đã gửi mã xác thực vào số điện thoại của bản. Vui lòng nhập mã xác thực để tiếp tục đăng nhập.",
    TXT_RESEND_OTP: "Gửi lại OTP",
    TXT_VERIFY_OTP: "Xác thực mã OTP",
    TXT_SMART_OTP_RETRY: "Mã OTP không chính xác.\nBạn còn {{retry}} lần để nhập SMART OTP",
    TXT_SMART_OTP_BLOCK: "Chúng tôi đã khóa tài khoản vì bạn đã nhập sai OTP 3 lần.\nHãy thử lại sau 5 phút nữa",
    TXT_SMART_OTP_EXPIRED: "Mã OTP của bạn đã hết hạn",
    TXT_SMART_OTP_LIMIT_ERROR: "Mã OTP hiện tại vẫn còn hiệu lực.",
    TXT_GRANT_ADMIN_PERMISSION: "Cấp quyền quản trị viên",
    TXT_REMOVE_ADMIN_PERMISSION: "Xóa quyền quản trị viên",
    TXT_GENERATE_NEW_ADMIN_PASSWORD: "Tạo mật khẩu mới cho quản trị viên",
    TXT_NEW_ADMIN_PASSWORD: "Mật khẩu mới của quản trị viên là: {{password}}",
    TXT_CONFIRM_ACTION: "Bạn có chắc chắn muốn thực hiện hành động này?",
    TXT_LOGIN_EXPIRED: "Phiên đăng nhập đã hết hạn",
    TXT_FILL_FORM: "Điền form",
    TXT_UPLOAD_FILE: "Tải file lên",
    TXT_ADD_NEW: "Thêm mới",
    TXT_USER: "Người dùng",
    TXT_UNIT: "Đơn vị",
    TXT_DEPARTMENT: "Phòng ban",
    TXT_POSITION: "Vị trí",
    TXT_STATUS: "Trạng thái",
    TXT_DOWNLOAD_SAMPLE_FILE: "Tải file mẫu",
    TXT_IMPORT_SUCCESS: "Tải file danh sách thành công",
    TXT_IMPORT_SUCCESS_MSG: "Những người dùng này sẽ nhận được lời mời tham gia sever qua email",
    TXT_IMPORT_FAILURE: "Tải file danh sách thất bại",
    TXT_IMPORT_FAILURE_MSG: "File không tải lên được do thông tin không chính xác\n Vui lòng tải lại file để chỉnh sửa",
    TXT_FILE_NOT_VALID: "File không hợp lệ",
    TXT_CHOOSE_ANOTHER_FILE_MSG: "Vui lòng chọn file khác",
    TXT_CHOOSE_UNIT: "Chọn đơn vị",
    TXT_CHOOSE_DEPARTMENT: "Chọn phòng ban",
    TXT_CHOOSE_POSITION: "Chọn vị trí",
    TXT_TITLE_UNIT_LIST: "Danh sách đơn vị/phòng ban",
    TXT_POSITION_NAME: "Tên vị trí",
    TXT_UNIT_NAME: "Tên đơn vị",
    TXT_DEPARTMENT_NAME: "Tên phòng ban",
    TXT_CREATED_AT: "Thời gian tạo",
    TXT_CREATE_POSITION_TITLE: "Tạo mới vị trí",
    TXT_EDIT_POSITION_TITLE: "Sửa thông tin vị trí",
    TXT_FROM_UNIT: "Thuộc đơn vị",
    TXT_FROM_DEPARTMENT: "Thuộc phòng ban",
    TXT_CREATE_DEPARTMENT: "Thêm phòng ban",
    TXT_MEMBER_MANAGEMENT: "Quản lý thành viên",
    TXT_CREATE_CHANNEL: "Tạo kênh",
    TXT_DELETE: "Xóa",
    TXT_CREATE_UNIT: "Tạo mới đơn vị",
    TXT_EDIT_UNIT: "Chỉnh sửa đơn vị",
    TXT_ENTER_UNIT_NAME: "Nhập tên đơn vị",
    TXT_CONFIRM_DELETE_UNIT:
      "Việc xóa đơn vị đồng thời sẽ xóa toàn bộ các phòng ban trực thuộc. Bạn có chắc chắn thực hiện không?",
    TXT_CONFIRM_DELETE_DEPARTMENT:
      "Việc xóa phòng ban đồng thời sẽ xóa toàn bộ các vị trí trực thuộc. Bạn có chắc chắn thực hiện không?",
    TXT_CREATE_DEPARTMENT_TITLE: "Tạo mới phòng ban",
    TXT_EDIT_DEPARTMENT: "Chỉnh sửa phòng ban",
    TXT_ENTER_DEPARTMENT_NAME: "Nhập tên phòng ban",
    TXT_MEMBER_LIST: "Danh sách thành viên trong phòng",
    TXT_ADD_MEMBER: "Thêm thành viên",
    TXT_CREATE_POSITION_SUCCESS: "Tạo mới vị trí thành công",
    TXT_UPDATE_POSITION_SUCCESS: "Cập nhật vị trí thành công",
    TXT_DELETE_POSITION_SUCCESS: "Đã xoá vị trí.",
    TXT_CONFIRM_DELETE_POSITION: "Bạn chắc chắn thực hiện xóa vị trí này?",
    TXT_CREATE_UNIT_SUCCESS: "Tạo mới đơn vị thành công",
    TXT_UPDATE_UNIT_SUCCESS: "Cập nhật đơn vị thành công",
    TXT_CREATE_UNIT_FAIL: "Tạo mới đơn vị thất bại",
    TXT_UNIT_DUPLICATED: "Tên đơn vị đã tồn tại",
    TXT_INVALID_UNIT_NAME: "Tên đơn vị không đúng định dạng",
    TXT_MISSING_FORM_PARAMS: "Dữ liệu truyền lên bị thiếu",
    TXT_NO_DEPARTMENTS: "Không có dữ liệu phòng ban",
    TXT_ADD_MEMBER_DIALOG: "Thêm thành viên vào phòng ban",
    TXT_CONFIRM_DELETE_MEMBER: "Bạn chắc chắn thực hiện xóa thành viên này?",
    TXT_NO_UNITS: "Không có dữ liệu đơn vị",
    TXT_UNIT_CONTAIN_DEPARTMENTS: "Không thể xóa đơn vị này, đơn vị đang có phòng ban trực thuộc",
    TXT_DELETE_UNIT_SUCCESS: "Đã xóa đơn vị.",
    TXT_CREATE_DEPARTMENT_SUCCESS: "Tạo mới phòng ban thành công",
    TXT_UPDATE_DEPARTMENT_SUCCESS: "Cập nhật phòng ban thành công",
    TXT_CREATE_DEPARTMENT_FAIL: "Tạo mới phòng ban thất bại",
    TXT_DEPARTMENT_DUPLICATED: "Tên phòng ban đã tồn tại",
    TXT_INVALID_DEPARTMENT_NAME: "Tên phòng ban không đúng định dạng",
    TXT_DEPARTMENT_CONTAIN_POSITIONS: "Không thể xóa phòng ban này, phòng ban đang có vị trí trực thuộc",
    TXT_DELETE_DEPARTMENT_SUCCESS: "Đã xóa phòng ban.",
    TXT_ADD_MEMBER_SUCCESS: "Thêm thành viên thành công",
    TXT_DELETE_MEMBER_SUCCESS: "Xóa thành viên thành công",
    TXT_CREATE_CHANNEL_SUCCESS: "Tạo mới kênh thành công",
    TXT_CREATE_CHANNEL_FAIL: "Tạo mới kênh thất bại",
    TXT_CHANNEL_DUPLICATED: "Kênh đã tồn tại",
    TXT_PHONE_DUPLICATED: "Số điện thoại đã tồn tại. Lời mời tham gia đã được gửi lại.",
    TXT_DATA_WRONG_FORMAT: "Dữ liệu gửi lên không đúng định dạng",
    TXT_CREATE_ACCOUNT_SUCCESS: "Thêm mới người dùng thành công",
    TXT_CREATE_ACCOUNT_FAIL: "Thêm mới người dùng thất bại",
    TXT_INVITE_SUCCESS: "Gửi lời mời tham gia thành công",
    TXT_INVITE_FAIL: "Gửi lời mời tham gia thất bại",

    // FORMATS
    FM_REMOVE_SINGLE_PERSON: "Bạn có muốn vô hiệu hóa {{name}} khỏi máy chủ?",
    FM_REMOVE_MULTIPLE_PEOPLE: "Bạn có muốn vô hiệu hóa {{number}} người khỏi máy chủ?",
    FM_ACTIVE_SINGLE_PERSON: "Bạn có muốn kích hoạt người dùng {{name}} không? ",
    FM_ACTIVE_MULTIPLE_PERSON: "Bạn có muốn kích hoạt {{number}} người không?",
    FM_UPLOAD_PHONE_FORMAT_WRONG: "Có {{number}} người dùng sai định dạng số điện thoại, hãy kiểm tra lại!",
    FM_COUNTDOWN_OTP: "(00:{{ time }})",
  },
};

export default vi;
