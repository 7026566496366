import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { Add, PermIdentityOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { useCreateBotMessage, useUpdatePosition } from "hooks";
import { Box, Button, InputAdornment, TextField, InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { SystemUtils, UnitUtils } from "utils";
import { PageTitle } from "components";
import { CreateMessageDialog } from "./components";

const MediaManagement = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const positionError = getLabel(LangConstant.OBJ_POSITION_ERROR, { returnObjects: true });
  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  const { createBotMessage } = useCreateBotMessage();
  const { updatePosition } = useUpdatePosition();
  const [actionDialogConfig, setActionDialogConfig] = useState({ open: false, position: null });

  const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState(false);

  const handleToggleOpenDialog = () => {
    setIsOpenDeleteConfirmDialog(!isOpenDeleteConfirmDialog);
  };

  const handleCloseActionDialog = () => {
    setActionDialogConfig({ open: false, position: null });
  };

  const handleCreateMessage = (content, time) => {
    if (!Boolean(content) || !Boolean(time)) {
      SystemUtils.appendNotification(getLabel(positionError[SystemConstant.POSITION_ERROR.IN_WRONG_FORMAT]), "error");
    } else {
        createBotMessage({
          content: content,
          time: time,
          onClose: handleCloseActionDialog,
        });
    }
  };

  const handleDraftMessage = content => {
    if (!Boolean(content)) {
      SystemUtils.appendNotification(getLabel(positionError[SystemConstant.POSITION_ERROR.IN_WRONG_FORMAT]), "error");
    } else {
        createBotMessage({
          content: content,
          onClose: handleCloseActionDialog,
        });
    }
  };

  const handleUpdatePosition = form => {
    if (!UnitUtils.isValidName(form.positionName)) {
      SystemUtils.appendNotification(getLabel(positionError[SystemConstant.POSITION_ERROR.IN_WRONG_FORMAT]), "error");
    } else {
      updatePosition({
        positionName: form.positionName,
        unitId: form.unitId,
        departmentId: form.departmentId,
        positionId: form.positionId,
        onClose: handleCloseActionDialog,
      });
    }
  };

  const handleOpenCreatePositionDialog = () => {
    setActionDialogConfig({ open: true, position: null });
  };

  const handleOpenEditPositionDialog = position => {
    setActionDialogConfig({ open: true, position: position });
  };

  return (
    <Fragment>
      <PageTitle>{getLabel(titleObjectContent.mediaManagement)}</PageTitle>
      <Paper sx={{ px: 2, py: 4 }}>
        <Box className={classes.btnContainer}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<PermIdentityOutlined fontSize="small" />}
            onClick={handleToggleOpenDialog}
          >
            Thông tin tài khoản
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<Add fontSize="small" />}
            onClick={handleOpenCreatePositionDialog}
          >
            Soạn tin nhắn mới
          </Button>
        </Box>
        {/* <Datatable
          positions={positions}
          pagination={pagination}

          onChangePage={handleChangePage}
          onSelectPosition={handleSelectedPosition}
          onClickEdit={handleOpenEditPositionDialog}
        /> */}
        <CreateMessageDialog
          open={actionDialogConfig.open}
          onClose={handleCloseActionDialog}
          onCreate={handleCreateMessage}
          onUpdate={handleUpdatePosition}
          onDraft={handleDraftMessage}
        />
      </Paper>
    </Fragment>
  );
};

export default MediaManagement;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 8,
  },

  btnContainer: {
    display: "flex",
    gap: 8,
  },

  filterContainer: {
    display: "flex",
    gap: 8,
  },

  textField: {
    width: "400px",
  },

  button: {
    height: "40px",
  },
}));
