import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { KeyConstant } from "const";
import { BranchUtils, StorageUtils } from "utils";

const Monitoring = () => {
  const currentBranchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);

  const [monitorUrl, setMonitorUrl] = useState(null);

  useEffect(() => {
    const currentBranch = BranchUtils.getCurrentBranch();
    setMonitorUrl(currentBranch.options?.monitoring_url);
  }, [currentBranchId]);

  return (
    Boolean(monitorUrl) && (
      <Box width="100%" height="100%" title="Monitoring" border="none" component="iframe" src={monitorUrl} />
    )
  );
};

export default Monitoring;
